<template>
    <nav class="mb-2">
        <ul class="nav justify-content-center">
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Gateaux sur mesure</a>

                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item nav-link" href="#">Cake Design</a>
                    </li>
                    <li><a class="dropdown-item nav-link" href="#">Layer Cake</a></li>
                    <li>
                        <a class="dropdown-item nav-link" href="#">Number Cake</a>
                    </li>
                    <li><a class="dropdown-item nav-link" href="#">Nuage</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Sweet table</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item nav-link" href="#">Cake Design</a>
                    </li>
                </ul>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" href="https://www.instagram.com/candyparadis/?hl=fr" target="_blank">Instagram</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" href="#buches">En ce moment</a>
            </li> -->
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Bonbons</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item nav-link" href="#">Gateaux bonbons</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">La vitrine</a>
            </li> -->
        </ul>
    </nav>
</template>

<script>
export default {
    name: "cp-Nav",
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.dropdown {
    position: unset;
}

nav.mb-2 {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.dropdown:hover .dropdown-menu {
    display: flex;
    justify-content: center;
    width: 634px;
    border: none;
    top: 25px;
    left: 83px;
}
.dropdown-menu li {
    display: inline;
}

.nav-link:focus,
.nav-link:hover {
    color: var(--primary);
}

.nav-link {
    display: inline;
    color: var(--primary);
    text-decoration: underline;
}
</style>
